$breakpoints: (
	'phone': 480px,
	'tablet': 768px,
	'desktop': 1024px,
	'widescreen': 1200px
) !default;

$muted: #4d5872;
$main: #242935;

$section-border: 3px solid #d9d9d9;

$card-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%);
$yellow: #e29824;
$blue: #0087dd;
$lato: Lato,sans-serif;
$red: #dd2c00;