@use '@angular/material' as mat;

@include mat.all-component-typographies();
@include mat.core();

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$gw-palette: (
  50: #e1f5ff,
  100: #b3e5ff,
  200: #80d5ff,
  300: #49c4ff,
  400: #1bb7ff,
  500: #00aaff,
  600: #009cf2,
  700: #0087dd,
  800: #0177c9,
  900: #0857a7,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ng-client-primary: mat.define-palette($gw-palette, 700);
$ng-client-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$ng-client-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ng-client-theme: mat.define-light-theme((
  color: (
    primary: $ng-client-primary,
    accent: $ng-client-accent,
    warn: $ng-client-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ng-client-theme);

/* You can add global styles to this file, and also import other style files */
@import 'src/variables.scss';
@import 'include-media';

body {
	margin: 0;
}

body { 
  height: 100%; 
}

html {
  background-color: white;
  color: #242935;
  -webkit-font-smoothing: antialiased;
}

body { 
  margin: 0;
  font-family: Roboto, "Helvetica Neue", 
  sans-serif; 
}

.gwc-form__field-group {
	display: flex;
  width: 100%;
  margin: 0 auto;
  gap: 30px;
  flex-wrap: wrap;

  @include media('<=phone') {
    gap: 0;
  }
}

.gwc-form__field-group--column {
  flex-direction: column;
  gap: 0;
}

.gwc-form__field-group--small {
  max-width: 550px;
}

.gwc-form__field-group--normal {
  max-width: 650px;

  .gwc-form__field {
    max-width: 650px;
  }
}

.gwc-form__field-group--hidden {
  display: none;
}

.gwc-form__field {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  margin-bottom: 16px; 
  display: block;
  max-width: 550px;
  min-width: 200px;

  .mat-mdc-form-field {
    width: 100%;
  }

  .mat-mdc-form-field-infix {
    width: auto;
  }

  &.gwc-form__field--small {
    max-width: 110px;
    min-width: 100px;
  }

  &.gwc-form__field--auto {
    width: auto;
    min-width: 100px;
  }
}

// .cdk-overlay-pane {
//   transform: translateY(-22px);
// }

.gwc-form__field--grow {
	flex: 1;
}

.gwc-form__field--no-max {
  max-width: unset;
}

.gwc-form__field--tight {
  margin-bottom: 12px;
}

.gwc-form__field--no-btm-margin {
  margin-bottom: 0;
}

.gwc-form__field--address-number {
  min-width: 0;
}

.gwc-form__field--hidden {
  display: none;
}

.gwc-form__heading {
  text-align: center;
}

.gwc-form__label {
  color: $blue;
  font-size: 18px;
  margin: 12px 0 24px 0;
  text-align: center;
}

.gwc-content-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.5em;
	padding-bottom: 1.3em;
	border-bottom: $section-border;

	h1 {
    line-height: 36px;
		color: #313e53;
    font-size: 36px;
    font-weight: 300;
		margin: 0;
    font-family: $lato;
	}
}

.gwc-travelers-item__progress-bar {
	.mdc-linear-progress__bar-inner {
		border-color: $yellow;
	}

  .mdc-linear-progress__bar {
    background-color: $yellow;
  }

  .mdc-linear-progress__buffer-bar {
    background-color: #efefef;
  }
}

.gwc-login__form {
  gwc-button,
	form,
	mat-form-field {
		width: 100%;
	}

	gwc-button {
		display: block;
		margin-top: 12px;
	}
}

.gwc-login__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    font-family: $lato;
    font-weight: 700;
  }

  p {
    color: $muted;
  }
}

.gwc-setup__alias-field {
  .mat-form-field-wrapper {
    margin-top: 0!important;
  }

  .mat-form-field-infix {
    border-top: 0;
  }

  .mat-form-field-subscript-wrapper {
    padding: 0!important;
  }
}

.gwc-card {
  position: relative;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: $card-shadow;
  transition: box-shadow .3s;
}

.gwc-social-security {
  .mat-form-field-infix {
    padding: 10px 0;
    border-top-width: 10px;
  }

  .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
    padding: 0;
    margin-top: 10px;
  }
}

.gwc-download-packet__dialog {
  .mat-dialog-container {
    width: 620px;
    padding: 0 0 24px;
    border-radius: 0;
    overflow: hidden;
    
    .mat-checkbox-label {
      font-weight: bold;
      color: #0087dd;
      white-space: initial;
      line-height: 16px;
    }
  }
}

// @include media('<=phone') {
//   .gwc-form__field-group {
//     flex-direction: column
//   }

//   .gwa-dialog-header {
//     .gwa-dialog-header__text {
//       font-size: 12px;
//     }
//   }
// }

.gwc-layout__dropmenu.mat-menu-panel {
  min-height: unset;

	> div {
		padding: 0!important;
	}
}

.mat-flat-button {
  background-color: #B3B3B3;
  color: #FFF;
}

.mat-dialog-container {
  padding: 0!important;
  border-radius: 0!important;
}

.gwc-dialog {
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
}

.gwc-dialog__body {
  flex: 1;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
}

.gwc-download-packet__download {
  .gwc-button.mat-button-disabled {
    color: #FFF;
    opacity: 0.5;
    background-color: $blue;
  }
}

.gwc-download-packet__form-checkbox {
  .mat-checkbox-label {
    white-space: normal!important;
  }
}

.mdc-button__label {
  letter-spacing: 0;
}

.gwc-shipping-confirmation__confirm-form {
  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }
}

.gwc-shipping-confirmation__confirm-button {
  .mat-mdc-unelevated-button {
    background-color: rgba(0, 135, 221, 1) !important;
  }

  .mat-mdc-unelevated-button[disabled] {
    background-color: rgba(0, 135, 221, 0.2) !important;
  }
}

.mat-drawer-container {
  color: $main;
}

.ngx-ic-overlay {
  outline: unset!important;
}

.gwc-shipdate-dialog__button
.mat-mdc-unelevated-button {
  min-width: 15em;
  min-height: 43.2px;
  padding: 0.75em;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 1.2;
  color: #fff;
  background-color: #0087dd;
  box-sizing: border-box;
}

.gwc-shipdate-dialog__button
.mat-mdc-unelevated-button[disabled] {
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(0, 135, 221, 0.5);

  svg {
    opacity: 0.5;
  }
}

.gwc-traveler-dialog-overlay .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  overflow-y: visible;
  position: absolute;
  width: unset;
  height: unset;
  bottom: 0;
}

.gwc-scanner-dialog-overlay .mat-mdc-dialog-container .mdc-dialog__surface {
	border-radius: 20px;
}

.gwc-ship-date__button .gwc-button.mdc-button {
  width: 200px;

  &[disabled] {
    background-color: #0087dd;
    color: #FFF;
    opacity: 0.6;
  }
}

.gwc-signature__input {
  .mat-mdc-text-field-wrapper,
  .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
    padding: 0;
  }

  .mdc-text-field--filled .mdc-line-ripple::after {
    border-bottom-color: #2D3342;
  }

  .mdc-text-field--focused .mdc-floating-label {
    color: #535050;
    font-weight: 400;
  }
}

.gwc-signature__tabs.mat-mdc-tab-group .mat-mdc-tab-labels {
  .mdc-tab.mat-mdc-tab {
    flex-grow: unset;
    text-transform: uppercase;
    padding-left: 0;
    min-width: unset;

    .mdc-tab__ripple,
    .mat-ripple {
      display: none;
    }

    .mdc-tab__text-label {
      color: rgba(0, 0, 0, 0.3);
      font-size: 11px;
      font-weight: 700;
      letter-spacing: 0;
      min-width: unset;
    }

    &.mdc-tab--active .mdc-tab__text-label {
      color: #2D3342;
    }
  }

  .mdc-tab-indicator {
    display: none;
  }
}

.gwc-signature__tabs .signature-pad-canvas {
  border: 1px solid #CCCCCC!important;
  border-radius: 2px;
  background-color: #FBFFDF;
}

.gwc-signature__disclaimer.mat-mdc-checkbox {
  .mdc-form-field {
    align-items: flex-start;
  }

  .mdc-checkbox {
    margin-top: -11px;
    margin-left: -11px;
  }
}

.gwc-request-form {
  .mdc-text-field--filled:not(:disabled) {
    background-color: transparent;
    padding-left: 0;
  }
}

.gwc-scanner-webcam {
	webcam,
	webcam div,
	webcam video {
		max-width: 100%;
	}
}

.gwc-esign-disclaimer__agree-checkbox {
  .mdc-form-field {
    align-items: flex-start;
  }

  .mdc-checkbox {
    margin-top: -11px!important;
  }
}

formly-field {
  max-width: 100%;
}

.gwc-esign-container {
  @include media('<=phone') {
    max-width: 100vw !important;
    max-height: 100vh;
    width: 100%;
    height: 100%;
  }
}

.gwc-error-msg {
  margin-top: 12px;
  color: #f44336;
  text-align: center;
  width: 100%;
}
